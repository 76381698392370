<template>
    <v-snackbar
            :value="dlgShow"
            @input="closeDlg"
            top
            :timeout="1500"             
            :color="dlgType"
            :multi-line="true" 
    >
        
     <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>{{ iconList[dlgType] }}</v-icon>

        <v-layout column>
          <div>
            <strong>{{ dlgTitle }}</strong>
          </div>
          <div>{{ dlgText }}</div>
        </v-layout>
        <v-spacer></v-spacer>
                <v-btn icon @click="closeDlg(false)">
            <v-icon >mdi-close-circle</v-icon>
        </v-btn>

      </v-layout>

    </v-snackbar>

     
</template>

<script>
    export default {
        name: "DlgMessage",
        props: {
            // dlgShow: Boolean,
            // dlgType: String,
            // dlgTitle: String,
            // dlgText: String,
        },
        data(){
            return{
                iconList:{success:"mdi-check-circle",error:"mdi-alert-circle",warning:"mdi-alert-circle"},
                dlgShow:false,
                dlgType:"",
                dlgTitle:"",
                dlgText:"",
            }
        },
        methods: {
            closeDlg(show) {
                this.$emit('dlgClose', show);
                this.dlgShow = show;
            },

            showDlgBox(show,type,title,text){
                this.dlgShow = show;
                this.dlgType = type;
                this.dlgTitle = title;
                this.dlgText = text;
                console.log(type)
            },
        },
    }
</script>